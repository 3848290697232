export const config = {
	"pwa": "https://pwa.congreet.com",
	"prodPwa": "https://pwa.congreet.com",
	"deeplink": "https://congreet.app.link",
	"appStoreLink": "https://itunes.apple.com/us/app/congreet/id1286636661?l=de&ls=1&mt=8",
	"playStoreLink": "https://play.google.com/store/apps/details?id=com.congreet.app2",
	"availableLangs": [
		"de",
		"en",
		"fr"
	],
	"hideSidebarPublicPages": false,
	"publicPagesLang": false,
	"hideLogout": false,
	"hideSettingsPasswordReset": false,
	"atsAnonymous": true,
	"noIframeRediret": "",
	"showInstallPrompt": true,
	"showCredentialWallet": false,
	"hideRatingInMembers": false,
	"sameSiteAssets": false,
	"maxAppointmentBlockSize": 60,
	"liveTranslation": true,
	"liveTranslationProd": false,
	"benefitUrl": {
		"en": "https://www.congreet.com/en/features-community-software-en/",
		"de": "https://www.congreet.com/de/features-community-software/"
	},
	"linkedIn": {
		"clientId": "78vsq6ziw3gfkz"
	},
	"mobileAppId": "com.congreet.app2",
	"customScheme": "congreet",
	"assets": "https://assets.coolgreet.de",
	"assetsProd": "https://assets.congreet.com",
	"trackingWhitelist": {
		"skillsclub.coolgreet.de": {
			"siteId": 3,
			"exclude": [
				"lost-password",
				"set-password",
				"login",
				"register",
				"toc",
				"credentials/",
				"credential/",
				"mail-setting",
				"deeplink",
				"unsubscribe",
				"preview-attendee-detail"
			],
			"communityWhitelist": {
				"610": 3
			}
		},
		"app.skillsclub.com": {
			"tracker": "https://matomo.skillsclub.com",
			"siteId": 4,
			"exclude": [
				"lost-password",
				"set-password",
				"login",
				"register",
				"toc",
				"credentials/",
				"credential/",
				"mail-setting",
				"deeplink",
				"unsubscribe",
				"preview-attendee-detail"
			],
			"communityWhitelist": {
				"655": 4
			}
		},
		"localhost": {
			"siteId": 1,
			"communityWhitelist": {
				"518": 2,
				"527": 1
			}
		}
	},
	"features": {
		"countryDropdown": {
			"app.skillsclub.com": {
				"communities": []
			},
			"v15.skillsclub.com": {
				"communities": [
					655
				]
			},
			"skillsclub.coolgreet.de": {
				"communities": [
					610
				]
			},
			"localhost": {
				"communities": [
					527
				]
			}
		},
		"limitedMemberList": {
			"app.skillsclub.com": {
				"communities": [
					655
				]
			},
			"v15.skillsclub.com": {
				"communities": [
					655
				]
			},
			"skillsclub.coolgreet.de": {
				"communities": [
					610
				]
			},
			"localhost": {
				"communities": [
					527
				]
			}
		},
		"disableInteraction": {
			"app.skillsclub.com": {
				"communities": []
			},
			"v15.skillsclub.com": {
				"communities": [
					655
				]
			},
			"skillsclub.coolgreet.de": {
				"communities": [
					610
				]
			},
			"localhost": {
				"communities": [
					527
				]
			}
		},
		"disableMessages": {
			"app.skillsclub.com": {
				"communities": []
			},
			"v15.skillsclub.com": {
				"communities": [
					655
				]
			},
			"skillsclub.coolgreet.de": {
				"communities": [
					610
				]
			},
			"localhost": {
				"communities": [
					527
				]
			}
		}
	}
}